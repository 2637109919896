import React, {useState} from 'react';
import axios from 'axios';
import './daler.css';

const DaleR = () => {
  const [data, setData] = useState({data: []});
  const [err, setErr] = useState('');
  const [state, setState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  /*useEffect(() => {
    setState(JSON.parse(window.localStorage.getItem('state')))
  }, []);

  useEffect(() => {
    window.localStorage.setItem('state', state);
  }, [state]);*/

  const handleClickOn = async () => {
    setState(!state);
    try {
      const {data} = await axios.post('/trigger/DaleR', {
        ammo: 'on'
      });
      setData(data);
    } catch (err) {
      setErr(err.message);
    } 
  };
  const handleClickOff = async () => {
    setState(!state);
    try {
      const {data} = await axios.post('/trigger/DaleR', {
        ammo: 'off'
      });
      setData(data);
    } catch (err) {
      setErr(err.message);
    } 
  };
  const handleClickDefault = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/two', {
        message: 'default'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickFilming = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/two', {
        message: 'filming'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickPause = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/two', {
        message: 'pause'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickResume = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/two', {
        message: 'resume'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickHide = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/two', {
        message: 'hide'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickShow = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/two', {
        message: 'show'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickNext = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/two', {
        message: 'next'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickFilming1 = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/one', {
        message: 'filming1'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickFilming2 = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/one', {
        message: 'filming2'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickFilming3 = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/one', {
        message: 'filming3'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickFilming4 = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/one', {
        message: 'filming4'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  

  console.log(data);

  return (
    <div className='daler__container'>
      <div className='message__container'>
        <div>
          {err && <h2>{err}</h2>}
          {state
          ? <h3>Geofence On</h3>
          : <h3>Geofence Off</h3>}
          <h1>DaleR</h1>
          {state
          ?<button className="daler__btnClassOff"   onClick={handleClickOff}>Geofence On</button>
          :<button className="daler__btnClassOn" onClick={handleClickOn}>Geofence Off</button>}
          <button className="dale_btn" onClick={handleClickDefault}>DaleR Calendar</button>
          <button className="dale_btn" onClick={handleClickHide}>DaleR Blackout</button>
          <button className="dale_btn" onClick={handleClickShow}>DaleR Show</button>
          <button className="dale_btn" onClick={handleClickPause}>DaleR Pause</button>
          <button className="dale_btn" onClick={handleClickResume}>DaleR Resume</button>
          <button className="dale_btn" onClick={handleClickNext}>DaleR Next</button>
          <button className="river_btn" onClick={handleClickFilming}>DaleR loop 1</button>
          <button className="river_btn" onClick={handleClickFilming1}>DaleR loop 2</button>
          <button className="river_btn" onClick={handleClickFilming2}>DaleR loop 3</button>
          <button className="river_btn" onClick={handleClickFilming3}>DaleR loop 4</button>
          <button className="river_btn" onClick={handleClickFilming4}>DaleR loop 5</button>
          
          {isLoading && <h2>Loading...</h2>}

        </div>
    </div>
    </div>
  );
};
export default DaleR;