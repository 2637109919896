import React, {useState} from 'react';
import axios from 'axios';
import './lauderdale.css';

const BioBus = () => {
  const [data, setData] = useState({data: []});
  const [err, setErr] = useState('');
  const [state, setState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  /*useEffect(() => {
    setState(JSON.parse(window.localStorage.getItem('state')))
  }, []);

  useEffect(() => {
    window.localStorage.setItem('state', state);
  }, [state]);*/

  //http://localhost:5000
  const handleClickOn = async () => {
    setState(!state);
    try {
      const {data} = await axios.post('/trigger/BioBus', {
        ammo: 'on'
      });

      setData(data);
    } catch (err) {
      setErr(err.message);
      
    } 
  };
  const handleClickOff = async () => {
    setState(!state);
    try {
      const {data} = await axios.post('/trigger/BioBus', {
        ammo: 'off'
      });

      setData(data);
    } catch (err) {
      setErr(err.message);
      
    } 
  };

  const handleClickDefault = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/four', {
        message: 'default'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickFilming = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/four', {
        message: 'filming'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickPause = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/four', {
        message: 'pause'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickResume = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/four', {
        message: 'resume'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickHide = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/four', {
        message: 'hide'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickShow = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/four', {
        message: 'show'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickNext = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/four', {
        message: 'next'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickFilming1 = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/one', {
        message: 'filming1'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickFilming2 = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/one', {
        message: 'filming2'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickFilming3 = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/one', {
        message: 'filming3'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickFilming4 = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/one', {
        message: 'filming4'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  console.log(data);

  return (
    <div className='lauderdale__container'>
      <div className='message__container'>
        <div>
          {err && <h2>{err}</h2>}
          {state
          ? <h3>Geofence On</h3>
          : <h3>Geofence Off</h3>}
          <h1>Lauderdale</h1>
          {state
          ?<button className="lauderdale__btnClassOff"   onClick={handleClickOff}>Geofence On</button>
          :<button className="lauderdale__btnClassOn" onClick={handleClickOn}>Geofence Off</button>}
          <button className="lauderdale_btn" onClick={handleClickDefault}>Lauderdale Calendar</button>
          <button className="lauderdale_btn" onClick={handleClickHide}>Lauderdale Blackout</button>
          <button className="lauderdale_btn" onClick={handleClickShow}>Lauderdale Show</button>
          <button className="lauderdale_btn" onClick={handleClickPause}>Lauderdale Pause</button>
          <button className="lauderdale_btn" onClick={handleClickResume}>Lauderdale Resume</button>
          <button className="lauderdale_btn" onClick={handleClickNext}>Lauderdale Next</button>
          <button className="river_btn" onClick={handleClickFilming}>Lauderdale loop 1</button>
          <button className="river_btn" onClick={handleClickFilming1}>Lauderdale loop 2</button>
          <button className="river_btn" onClick={handleClickFilming2}>Lauderdale loop 3</button>
          <button className="river_btn" onClick={handleClickFilming3}>Lauderdale loop 4</button>
          <button className="river_btn" onClick={handleClickFilming4}>Lauderdale loop 5</button>

          {isLoading && <h2>Loading...</h2>}
        </div>
      </div>
    </div>
  );
};

export default BioBus;