import React from 'react';
import './controlcenter.css'
import { TampaTwo, TampaThree } from '../digitalBoats';
import { TAMPA_VESSELS } from '../../config/vessels';
import useAuth from "../../hooks/useAuth";

const TampaDigitals = () => {

    const { vessels: allowedVessels, isSuperAdmin } = useAuth();
    const isAllowed = (vessel) => {
        return allowedVessels.includes(vessel);
    }

    let t2Allowed = isAllowed(TAMPA_VESSELS.T2);
    let t3Allowed = isAllowed(TAMPA_VESSELS.T3); 

    return(
        <div className='controller'>
            {(isSuperAdmin || t2Allowed) && <TampaTwo />}

            {(isSuperAdmin || t3Allowed) && <TampaThree />}
            
        </div>
    );
}
 
export default TampaDigitals;