import React from 'react';
import { Link } from 'react-router-dom'; //Code from new build
//import {auth} from '../../utils/firebase';
//import {useAuthState} from 'react-firebase-hooks/auth';
import './home.css';


const Home = () => {
    //const [user, loading] = useAuthState(auth);
    //if(loading) return <h1>loading...</h1>
   
    return (
        <div className="home__page">
            <div className="header">
                
            </div>
            <div className="user__message">
                <h1>Ballyhoo Control Center</h1>
                <br/>
                <br/>
                <h2>Welcome to the Ballyhoo Control Center</h2>
                <br></br>
                <p>The control center allows you to control the content on the screens.</p> 
                <br></br>  
                <p>Please Login to access your user profile.</p>
                <p id='login'><Link to="/login">Login</Link></p>    
            </div>      
        </div>
    );
}

export default Home;