import React from 'react';
import ReactDOM from 'react-dom';
//import ReactDOM from 'react-dom/client';
//import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
//import './indexT.css';
import './index.css'

import { store } from './app/store'
import { Provider } from 'react-redux'

//new dev code as 7-27-2023 @12:15pm
/*const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<App/>}/>
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
)*/


//current working index code 7-27-2023 @12:01pm
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </Provider>, 
document.getElementById('root')
);


