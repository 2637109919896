import React, {useState} from 'react';
import axios from 'axios';
import './ballyhooThree.css';


const BallyhooThree = () => {
  const [data, setData] = useState({data: []});
  const [err, setErr] = useState('');
  const [geostate, setGeoState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //B3
  const handleClickB3Pause = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b3command', {
        message: 'pause'
      });
      
      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB3Resume = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b3command', {
        message: 'resume'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB3Next = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b3command', {
        message: 'next'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB3Previous = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b3command', {
        message: 'previous'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB3Blackout = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b3command', {
        message: 'hide'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB3LightUp = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b3command', {
        message: 'show'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB3Logo = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b3command', {
        message: 'logo'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB3TestScreens = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b3command', {
        message: 'test'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB3Calendar = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b3command', {
        message: 'default'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB3FilmingOne = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b3command', {
        message: 'Filming one'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB3FilmingTwo = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b3command', {
        message: 'Filming two'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB3FilmingThree = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b3command', {
        message: 'Filming three'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB3FilmingFour = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b3command', {
        message: 'Filming four'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB3FilmingFive = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b3command', {
        message: 'Filming five'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB3LiveTv = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b3command', {
        message: 'live tv'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickStartgeofence = async () => {
    setGeoState(!geostate);
    try {
      const {data} = await axios.post('/digitals/b3geofence', { //http://localhost:5000/digitals/b3geofence
        ammo: 'on'
      });

      setData(data);
    } catch (err) {
      setErr(err.message);
      
    } 
  };
  const handleClickStopgeofence = async () => {
    setGeoState(!geostate);
    try {
      const {data} = await axios.post('/digitals/b3geofence', {
        ammo: 'off'
      });

      setData(data);
    } catch (err) {
      setErr(err.message);
      
    } 
  };

  console.log(data);

  return (
    
    <div className='digital__container'>
      <div className='message__container'>
        <div>
          {err && <h2>{err}</h2>}
          {geostate
          ? <h3>Geofence On</h3>
          : <h3>Geofence Off</h3>}
          <h1>B3</h1>
          {geostate
          ?<button className="digitalgeo__btnClassOff"  onClick={handleClickStopgeofence}>Geofence On</button>
          :<button className="digitalgeo__btnClassOn" onClick={handleClickStartgeofence}>Geofence Off</button>}
          
          <button className="digital__btn" onClick={handleClickB3Calendar}>B3 Calendar</button>
          <button className="digital__btn" onClick={handleClickB3Pause}>B3 Pause</button>
          <button className="digital__btn" onClick={handleClickB3Resume}>B3 Resume</button>
          <button className="digital__btn" onClick={handleClickB3Next}>B3 Next</button>
          <button className="digital__btn" onClick={handleClickB3Previous}>B3 Previous</button>
          <button className="digital__btn" onClick={handleClickB3Blackout}>B3 Blackout</button>
          <button className="digital__btn" onClick={handleClickB3LightUp}>B3 Light Up</button>
          <button className="digital__btn" onClick={handleClickB3Logo}>B3 Logo</button>
          <button className="digital__btn" onClick={handleClickB3TestScreens}>B3 Test Screens</button>
          <button className="digital__btn" onClick={handleClickB3FilmingOne}>B3 Loop One</button>
          <button className="digital__btn" onClick={handleClickB3FilmingTwo}>B3 Loop Two</button>
          <button className="digital__btn" onClick={handleClickB3FilmingThree}>B3 Loop Three</button>
          <button className="digital__btn" onClick={handleClickB3FilmingFour}>B3 Loop Four</button>
          <button className="digital__btn" onClick={handleClickB3FilmingFive}>B3 Loop Five</button>
          <button className="digital__btn" onClick={handleClickB3LiveTv}>B3 Live TV</button>

          {isLoading && <h2>Loading...</h2>}

        </div>
      </div>
    </div>
    
  );
};

export default BallyhooThree;


