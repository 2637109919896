import { useSelector } from 'react-redux'
import { selectCurrentToken } from "../features/auth/authSlice"
import jwtDecode from 'jwt-decode'

const useAuth = () => {
    const token = useSelector(selectCurrentToken)
    let isAdmin = false
    let isSuperAdmin = false
    let isFtl = false
    let isTampa = false
    let isMiami = false
    let isSeattle = false
    let status = ''

    if (token) {
        const decoded = jwtDecode(token)
        const { username, roles, vessels } = decoded.UserInfo
        isMiami = roles.includes('Miami')
        isTampa = roles.includes('Tampa')
        isFtl = roles.includes('Ftl')
        isSeattle = roles.includes('Seattle')
        isAdmin = roles.includes('Admin')
        isSuperAdmin = roles.includes('Super')


        if (isMiami) status = ( status === "" ? "Miami" : status + ", Miami" );
        if (isTampa) status = ( status === "" ? "Tampa" : status + ", Tampa" )
        if (isFtl) status = ( status === "" ? "Ftl" : status + ", Ftl" )
        if (isAdmin) status = ( status === "" ? "Admin" : status + ", Admin" ) //isManager
        if (isSuperAdmin) status = ( status === "" ? "Super" : status + ", Super" ) //isAdmin

        return { username, roles, status, isMiami, isTampa, isFtl, isSeattle, vessels, isAdmin, isSuperAdmin }
    }

    return { username: '', roles: [], isMiami, isTampa, isFtl, isAdmin, isSuperAdmin, status }
}
 
export default useAuth;