import React from 'react';
import {Riverwalk, DaleR, BioBus, Glades, Bayview} from '../watertaxis/index'
import './controlcenter.css'
import { FTL_VESSELS } from '../../config/vessels';
import useAuth from "../../hooks/useAuth";



const Control = () => {

    const { vessels: allowedVessels, isSuperAdmin } = useAuth();
    const isAllowed = (vessel) => {
        return allowedVessels.includes(vessel);
    }

    let riverwalkAllowed = isAllowed(FTL_VESSELS.Riverwalk);
    let daleRAllowed = isAllowed(FTL_VESSELS.DaleR);
    let lauderdaleAllowed = isAllowed(FTL_VESSELS.Lauderdale); 
    let riovistaAllowed = isAllowed(FTL_VESSELS.Riovista);
    let bayviewAllowed = isAllowed(FTL_VESSELS.Bayview);

    return(
        <div className='controller'>
            {(isSuperAdmin || riverwalkAllowed) && <Riverwalk />}

            {(isSuperAdmin || daleRAllowed) && <DaleR />}

            {(isSuperAdmin || lauderdaleAllowed) && <BioBus />}

            {(isSuperAdmin || riovistaAllowed) && <Glades />}

            {(isSuperAdmin || bayviewAllowed) && <Bayview />}

        </div>
    );
}

export default Control;
