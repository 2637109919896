import React, {useState} from 'react';
import axios from 'axios';
import './seattle.css';


const Seattle = () => {
  const [data, setData] = useState({data: []});
  const [err, setErr] = useState('');
  //const [state, setState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (event) => {
    setIsLoading(true);
    const message = event.target.id;

    try {
      const {data} = await axios.post('/seattletrigger', { //http://localhost:5000/seattletrigger
        message: message
      });
      
      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  console.log(data);

  return (

    <div className='digital__container'>
      <div className='message__container'>
      {err && <h2>{err}</h2>}
      <h1>Seattle</h1>
        <div>
          <button id="default" className="digital__btn" onClick={handleClick}>Seattle Calendar</button>
          <button id="pause" className="digital__btn" onClick={handleClick}>Seattle Pause</button>
          <button id="resume" className="digital__btn" onClick={handleClick}>Seattle Resume</button>
          <button id="next" className="digital__btn" onClick={handleClick}>Seattle Next</button>
          <button id="previous" className="digital__btn" onClick={handleClick}>Seattle Previous</button>
          <button id="hide" className="digital__btn" onClick={handleClick}>Seattle Blackout</button>
          <button id="show" className="digital__btn" onClick={handleClick}>Seattle Light Up</button>
          <button id="logo" className="digital__btn" onClick={handleClick}>Seattle Logo</button>
          <button id="test" className="digital__btn" onClick={handleClick}>Seattle Test Screens</button>
          <button id="filming one" className="digital__btn" onClick={handleClick}>Seattle Loop One</button>
          <button id="filming two" className="digital__btn" onClick={handleClick}>Seattle Loop Two</button>
          <button id="filming three" className="digital__btn" onClick={handleClick}>Seattle Loop Three</button>
          <button id="filming four" className="digital__btn" onClick={handleClick}>Seattle Loop Four</button>
          <button id="filming five" className="digital__btn" onClick={handleClick}>Seattle Loop Five</button>
          <button id="live tv" className="digital__btn" onClick={handleClick}>Seattle PAMM</button>        

          {isLoading && <h2>Loading...</h2>}

        </div>
      </div>
    </div>
    
  );
};

export default Seattle;
