import React, {useState} from 'react';
import axios from 'axios';
import './tampaTwo.css';



const TampaTwo = () => {
  const [data, setData] = useState({data: []});
  const [err, setErr] = useState('');
  //const [state, setState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  //switch presentation buttons
  const handleClickT2Pause = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/t2command', {
        message: 'pause'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickT2Resume = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/t2command', {
        message: 'resume'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickT2Next = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/t2command', {
        message: 'next'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickT2Previous = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/t2command', {
        message: 'previous'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickT2Blackout = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/t2command', {
        message: 'hide'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickT2LightUp = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/t2command', {
        message: 'show'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickT2Logo = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/t2command', {
        message: 'logo'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickT2TestScreens = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/t2command', {
        message: 'test'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickT2Calendar = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/t2command', {
        message: 'default'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickT2FilmingOne = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/t2command', {
        message: 'Filming one'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickT2FilmingTwo = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/t2command', {
        message: 'Filming two'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickT2FilmingThree = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/t2command', {
        message: 'Filming three'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickT2FilmingFour = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/t2command', {
        message: 'Filming four'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickT2FilmingFive = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/t2command', {
        message: 'Filming five'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  console.log(data);
  

  return (
    
    <div className='digital__container'>
      <div className='message__container'>
        <div>
      
          {err && <h2>{err}</h2>}
          <h1>T2</h1>
          <button className="digital__btn" onClick={handleClickT2Calendar}>T2 Calendar</button>
          <button className="digital__btn" onClick={handleClickT2Pause}>T2 Pause</button>
          <button className="digital__btn" onClick={handleClickT2Resume}>T2 Resume</button>
          <button className="digital__btn" onClick={handleClickT2Next}>T2 Next</button>
          <button className="digital__btn" onClick={handleClickT2Previous}>T2 Previous</button>
          <button className="digital__btn" onClick={handleClickT2Blackout}>T2 Blackout</button>
          <button className="digital__btn" onClick={handleClickT2LightUp}>T2 Light Up</button>
          <button className="digital__btn" onClick={handleClickT2Logo}>T2 Logo</button>
          <button className="digital__btn" onClick={handleClickT2TestScreens}>T2 Test Screens</button>
          <button className="digital__btn" onClick={handleClickT2FilmingOne}>T2 Filming One</button>
          <button className="digital__btn" onClick={handleClickT2FilmingTwo}>T2 Filming Two</button>
          <button className="digital__btn" onClick={handleClickT2FilmingThree}>T2 Filming Three</button>
          <button className="digital__btn" onClick={handleClickT2FilmingFour}>T2 Filming Four</button>
          <button className="digital__btn" onClick={handleClickT2FilmingFive}>T2 Filming Five</button>

          {isLoading && <h2>Loading...</h2>}

        </div>
      </div>
    </div>
    
  );
};

export default TampaTwo;