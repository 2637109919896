import { useLocation, Navigate, Outlet } from "react-router-dom"
import useAuth from "../../hooks/useAuth"

const RequireAuth = ({ allowedRoles }) => {
    const location = useLocation()
    const { roles } = useAuth()
    //console.log("roles" + roles)
    const content = (
        roles.some(role => allowedRoles.includes(role))
            ? <Outlet />
            : <Navigate to="/login" state={{ from: location }} replace />
    )

    return content
}

export const RequireAuthVessels = ({ allowedVessels }) => {
    const location = useLocation()
    const { vessels } = useAuth()
    //console.log("vessels" + vessels)
    const content = (
        vessels.some(vessel => allowedVessels.includes(vessel))
            ? <Outlet />
            : <Navigate to="/login" state={{ from: location }} replace />
    )

    return content
}

export default RequireAuth