import React, {useState} from 'react';
import axios from 'axios';
import './ballyhooTwo.css';



const BallyhooTwo = () => {
  const [data, setData] = useState({data: []});
  const [err, setErr] = useState('');
  //const [state, setState] = useState(false);
  const [geostate, setGeoState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //B2
  const handleClickB2Pause = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b2command', {
        message: 'pause'
      });
      
      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB2Resume = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b2command', {
        message: 'resume'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB2Next = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b2command', {
        message: 'next'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB2Previous = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b2command', {
        message: 'previous'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB2Blackout = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b2command', {
        message: 'hide'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB2LightUp = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b2command', {
        message: 'show'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB2Logo = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b2command', {
        message: 'logo'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB2TestScreens = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b2command', {
        message: 'test'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB2Calendar = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b2command', {
        message: 'default'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB2FilmingOne = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b2command', {
        message: 'Filming one'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB2FilmingTwo = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b2command', {
        message: 'Filming two'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB2FilmingThree = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b2command', {
        message: 'Filming three'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB2FilmingFour = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b2command', {
        message: 'Filming four'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB2FilmingFive = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b2command', {
        message: 'Filming five'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB2LiveTv = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b2command', {
        message: 'live tv'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  /*const handleClickB2StartLogs = async () => {
    setState(!state);
    try {
      const {data} = await axios.post('/logs/b2log', {
        ammo: 'on'
      });

      setData(data);
    } catch (err) {
      setErr(err.message);
      
    } 
  };
  const handleClickB2StopLogs = async () => {
    setState(!state);
    try {
      const {data} = await axios.post('/logs/b2log', {
        ammo: 'off'
      });

      setData(data);
    } catch (err) {
      setErr(err.message);
      
    } 
  };*/
  const handleClickB2Startgeofence = async () => {
    setGeoState(!geostate);
    try {
      const {data} = await axios.post('/digitals/b2geofence', { //http://localhost:5000/digitals/b2geofence
        ammo: 'on'
      });

      setData(data);
    } catch (err) {
      setErr(err.message);
      
    } 
  };
  const handleClickB2Stopgeofence = async () => {
    setGeoState(!geostate);
    try {
      const {data} = await axios.post('/digitals/b2geofence', {
        ammo: 'off'
      });

      setData(data);
    } catch (err) {
      setErr(err.message);
      
    } 
  };

  console.log(data);

  return (
    
    <div className='digital__container'>
      <div className='message__container'>
        <div>
          {err && <h2>{err}</h2>}
          {/*{state
          ? <h3>Logging On</h3>
          : <h3>Logging Off</h3>}*/}
          {geostate
          ? <h3>Geofence On</h3>
          : <h3>Geofence Off</h3>}
          <h1>B2</h1>
          {/*state
          ?<button className="digital2__btnClassOff"  onClick={handleClickB2StopLogs}>Logging On</button>
          :<button className="digital2__btnClassOn" onClick={handleClickB2StartLogs}>Logging Off</button>*/}
          {geostate
          ?<button className="digitalgeo__btnClassOff"  onClick={handleClickB2Stopgeofence}>Geofence On</button>
          :<button className="digitalgeo__btnClassOn" onClick={handleClickB2Startgeofence}>Geofence Off</button>}

      
          <button className="digital__btn" onClick={handleClickB2Calendar}>B2 Calendar</button>
          <button className="digital__btn" onClick={handleClickB2Pause}>B2 Pause</button>
          <button className="digital__btn" onClick={handleClickB2Resume}>B2 Resume</button>
          <button className="digital__btn" onClick={handleClickB2Next}>B2 Next</button>
          <button className="digital__btn" onClick={handleClickB2Previous}>B2 Previous</button>
          <button className="digital__btn" onClick={handleClickB2Blackout}>B2 Blackout</button>
          <button className="digital__btn" onClick={handleClickB2LightUp}>B2 Light Up</button>
          <button className="digital__btn" onClick={handleClickB2Logo}>B2 Logo</button>
          <button className="digital__btn" onClick={handleClickB2TestScreens}>B2 Test Screens</button>
          <button className="digital__btn" onClick={handleClickB2FilmingOne}>B2 Loop One</button>
          <button className="digital__btn" onClick={handleClickB2FilmingTwo}>B2 Loop Two</button>
          <button className="digital__btn" onClick={handleClickB2FilmingThree}>B2 Loop Three</button>
          <button className="digital__btn" onClick={handleClickB2FilmingFour}>B2 Loop Four</button>
          <button className="digital__btn" onClick={handleClickB2FilmingFive}>B2 Loop Five</button>
          <button className="digital__btn" onClick={handleClickB2LiveTv}>B2 PAMM</button>

          {isLoading && <h2>Loading...</h2>}

        </div>
      </div>
    </div>
    
  );
};

export default BallyhooTwo;


