import React, {useState} from 'react';
import axios from 'axios';
import './ballyhooOne.css';


const BallyhooOne = () => {
  const [data, setData] = useState({data: []});
  const [err, setErr] = useState('');
  //const [state, setState] = useState(false);
  const [geostate, setGeoState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //B1
  const handleClickB1Pause = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b1command', {
        message: 'pause'
      });
      
      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB1Resume = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b1command', {
        message: 'resume'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB1Next = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b1command', {
        message: 'next'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB1Previous = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b1command', {
        message: 'previous'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB1Blackout = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b1command', {
        message: 'hide'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB1LightUp = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b1command', {
        message: 'show'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB1Logo = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b1command', {
        message: 'logo'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB1TestScreens = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b1command', {
        message: 'test'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB1Calendar = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b1command', {
        message: 'default'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB1FilmingOne = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b1command', {
        message: 'Filming one'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB1FilmingTwo = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b1command', {
        message: 'Filming two'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB1FilmingThree = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b1command', {
        message: 'Filming three'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB1FilmingFour = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b1command', {
        message: 'Filming four'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB1FilmingFive = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b1command', {
        message: 'Filming five'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickB1LiveTv = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/b1command', {
        message: 'live tv'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  //logs
  /*const handleClickB1StartLogs = async () => {
    setState(!state);
    try {
      const {data} = await axios.post('/logs/b1log', {
        ammo: 'on'
      });

      setData(data);
    } catch (err) {
      setErr(err.message);
      
    } 
  };
  const handleClickB1StopLogs = async () => {
    setState(!state);
    try {
      const {data} = await axios.post('/logs/b1log', {
        ammo: 'off'
      });

      setData(data);
    } catch (err) {
      setErr(err.message);
      
    } 
  };*/
  const handleClickStartgeofence = async () => {
    setGeoState(!geostate);
    try {
      const {data} = await axios.post('/digitals/b1geofence', { //http://localhost:5000/digitals/b1geofence
        ammo: 'on'
      });

      setData(data);
    } catch (err) {
      setErr(err.message);
      
    } 
  };
  const handleClickStopgeofence = async () => {
    setGeoState(!geostate);
    try {
      const {data} = await axios.post('/digitals/b1geofence', {
        ammo: 'off'
      });

      setData(data);
    } catch (err) {
      setErr(err.message);
      
    } 
  };

  console.log(data);

  return (
    
    <div className='digital__container'>
      <div className='message__container'>
        <div>
      
          {err && <h2>{err}</h2>}
          {/*{state
          ? <h3>Logging On</h3>
          : <h3>Logging Off</h3>}*/}
          {geostate
          ? <h3>Geofence On</h3>
          : <h3>Geofence Off</h3>}
          <h1>B1</h1>
          {/*state
          ?<button className="digital__btnClassOff"  onClick={handleClickB1StopLogs}>Logging On</button>
          :<button className="digital__btnClassOn" onClick={handleClickB1StartLogs}>Logging Off</button>*/}
          {geostate
          ?<button className="digitalgeo__btnClassOff"  onClick={handleClickStopgeofence}>Geofence On</button>
          :<button className="digitalgeo__btnClassOn" onClick={handleClickStartgeofence}>Geofence Off</button>}

          <button className="digital__btn" onClick={handleClickB1Calendar}>B1 Calendar</button>
          <button className="digital__btn" onClick={handleClickB1Pause}>B1 Pause</button>
          <button className="digital__btn" onClick={handleClickB1Resume}>B1 Resume</button>
          <button className="digital__btn" onClick={handleClickB1Next}>B1 Next</button>
          <button className="digital__btn" onClick={handleClickB1Previous}>B1 Previous</button>
          <button className="digital__btn" onClick={handleClickB1Blackout}>B1 Blackout</button>
          <button className="digital__btn" onClick={handleClickB1LightUp}>B1 Light Up</button>
          <button className="digital__btn" onClick={handleClickB1Logo}>B1 Logo</button>
          <button className="digital__btn" onClick={handleClickB1TestScreens}>B1 Test Screens</button>
          <button className="digital__btn" onClick={handleClickB1FilmingOne}>B1 Loop One</button>
          <button className="digital__btn" onClick={handleClickB1FilmingTwo}>B1 Loop Two</button>
          <button className="digital__btn" onClick={handleClickB1FilmingThree}>B1 Loop Three</button>
          <button className="digital__btn" onClick={handleClickB1FilmingFour}>B1 Loop Four</button>
          <button className="digital__btn" onClick={handleClickB1FilmingFive}>B1 Loop Five</button>
          <button className="digital__btn" onClick={handleClickB1LiveTv}>B1 PAMM</button>
          

          {isLoading && <h2>Loading...</h2>}

        </div>
      </div>
    </div>
    
  );
};

export default BallyhooOne;


